import {BikiniSection} from '../types/bikini'

import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'

import Layout from '../layout'
import SectionsTemplate from '../templates/sections'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {contentImages} from '../helpers/text'

const NewsletterContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
    margin-top: -20px;
  }
`
const FormInfos = styled.div`
  flex: 1;
  font-size: 0.85rem;
  font-weight: 600;
  color: ${colors.secondary};
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 60px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 30px;
  }
`
const Form = styled.form`
  flex: 1;
  * {
    display: block;
    width: 100%;
  }
`
const Input = styled.input`
  border: none;
  background: none;
  border-bottom: 2px solid ${colors.lightGrey};
  padding: 5px 0;
  margin: 8px 0 18px;
  font-weight: 600;
  ::placeholder {
    font-weight: 600;
    color: ${colors.secondary};
  }
`
const Button = styled.button`
  margin: 50px 0 15px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 30px 0;
  }
`

const Newsletter: React.FC = () => (
  <NewsletterContainer>
    <FormInfos>
      <p>* Champs requis</p>
      <p>Ces informations restent 100% confidentielles, elles ne seront revendues ou cédées à personne</p>
    </FormInfos>
    <Form method="post" action={process.env.GATSBY_MAILCHIMP}>
      <Input type="email" name="EMAIL" placeholder="Email*" aria-label="E-mail" />
      <Input type="text" name="LNAME" placeholder="Nom*" aria-label="Nom" />
      <Input type="text" name="FNAME" placeholder="Prénom*" aria-label="Prénom" />
      <Button type="submit">S'inscrire</Button>
    </Form>
  </NewsletterContainer>
)

const extras = {
  newsletter: Newsletter,
}

const InfosPage = ({data}) => {
  const sections: BikiniSection[] = data.allBikiniSection.edges.map(contentImages)

  return (
    <Layout title="Infos pratiques">
      <SectionsTemplate sections={sections} extras={extras} />
    </Layout>
  )
}

export default InfosPage

export const query = graphql`
  query {
    allBikiniSection(filter: {page: {eq: "infos"}}, sort: {fields: [order], order: ASC}) {
      edges {
        node {
          slug
          title
          content
          childrenFile {
            childImageSharp {
              fluid(maxWidth: 1320) {
                # TODO: only create one version
                src
              }
            }
            url
          }
        }
      }
    }
  }
`
